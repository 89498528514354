import React from 'react'

const TeamExpert = () => {
  return (
    <>
      <section>
        <a href='https://www.linkedin.com/in/talbots-commercial-b42a96267' target='_blank'>
          <div className='team-Expert'>
            <img src="images/team-expert.png" className='Team-expertImg' />
            <div className='EXPERT-CNTENT'>
              <h1 className='TEAM--Center--Heading'>Our Team of Experts</h1>
            </div>
          </div>
        </a>
      </section>
    </>
  )
}

export default TeamExpert
