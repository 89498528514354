import React from 'react'

const Homescreen = () => {
    return (
        <>
            <div className="small-text">
                <h1>Experience the <br /> Difference</h1>
                <div className='center_line'>
                    <hr className='horizontal-line' />
                </div>
                <div className='paragraph'>
                    <p>We are your trusted partner for all your commercial real estate needs. With our extensive expertise and deep understanding of the industry, we provide exceptional service and deliver successful outcomes. Experience the difference and let us help you achieve your real estate goals.</p>
                </div>
            </div>
        </>
    )
}
export default Homescreen