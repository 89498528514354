import React from "react";
import { AiOutlineCaretLeft } from "react-icons/ai";
import { AiOutlineCaretRight } from "react-icons/ai";
import TeamExpert from "./TeamExpert";
import LatestList from "../LatestList/LatestList";


const About = () => {
  return (
    <>

      <section className="about_us">
        <div id="about">
          <div className="container">
            <div className="content">
              <div className="title">
                <h1><span><AiOutlineCaretLeft className='arrow' />  About Us <AiOutlineCaretRight className='arrow' /></span></h1>
                <p>At Talbots Commercial, we are a dedicated team of
                  professionals specializing in commercial real estate. With
                  years of experience in the industry, we bring a wealth of
                  knowledge and expertise to every transaction. Our commitment
                  to exceptional service and client satisfaction sets us
                  apart. We take pride in understanding our clients' unique
                  needs and providing tailored solutions to meet their goals.
                  Trust us to guide you through the complex world of
                  commercial real estate with integrity, professionalism, and
                  a focus on delivering results.</p>
              </div>
              <div className="image-section">
                <img className="talbot-img" src="images/Rectangle 123.png" />
              </div>
            </div>
          </div>
          <LatestList />
          <TeamExpert />
        </div>


      </section>
    </>
  );
};

export default About;