import "./App.css";
import Index from "./Home/Index";

function App() {
  return (
    <>
      <Index />
    </>
  );
}

export default App;