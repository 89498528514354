import React from "react";
import Navbar from "../Components/Navbar";
import About from "../About/About";
import Footer from "../Footer/Footer";

const Index = () => {
  return (
    <>
      <Navbar />
      <About />

      <Footer />
    </>
  );
};

export default Index;