import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import bgImage from "../video/Talbot-Business.mp4";
import Homescreen from "../Home/Homescreen";
const Navbar = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  useEffect(() => {
    const videoElement = document.getElementById("background-video");
    videoElement.addEventListener("loadeddata", handleVideoLoaded);
    return () => {
      videoElement.removeEventListener("loadeddata", handleVideoLoaded);
    };
  }, []);
  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };
  window.addEventListener("scroll", function () {
    var navbar = document.querySelector(".sticky-navbar");
    var scrollPosition = window.scrollY;
    if (scrollPosition > 0) {
      navbar.classList.add("scrolled");
    } else {
      navbar.classList.remove("scrolled");
    }
  });
  return (
    <div className="hero">
      <video
        id="background-video"
        autoPlay
        loop
        muted
        className="back-video"
        onLoadedData={handleVideoLoaded}
      >
        <source src={bgImage} type="video/mp4" />
      </video>
      <nav className="sticky-navbar">
        <img className="logo" src="images/logo.png" alt="Logo" />
        <input type="checkbox" id="menu-toggle" style={{ display: "none" }} />
        <label htmlFor="menu-toggle" className="Menu-Icon">
          <span></span>
          <span></span>
          <span></span>
        </label>
        <ul className="menu">
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <Link to="about">
              <a href="#">About Us</a>
            </Link>
          </li>
          <li>
            <Link to="services">
              <a href="#">Services</a>
            </Link>
          </li>
          <li>
            <Link to="contact">
              <a
                href="#"
                className="orange-btn"
                style={{
                  textDecoration: "none",
                  listStyle: "none",
                }}
              >
                Contact Us
              </a>
            </Link>
          </li>
        </ul>
      </nav>
      {!videoLoaded && (
        <div
          className="loader"
          style={{
            backgroundColor: "white",
            height: "100%",
            width: "100%",
            zIndex: 10000,
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <img
              className="talbot-img"
              src="images/loder1.gif"
              alt="Loader Image"
            />
          </div>
        </div>
      )}
      <Homescreen />
    </div>
  );
};
export default Navbar;





