import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { AiTwotoneFire } from "react-icons/ai";
import { AiFillHome } from "react-icons/ai";
import { FaDollarSign } from "react-icons/fa";





const LatestList = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 480 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 540, min: 0 },
      items: 1
    },

  };
  return (
    <>
      <div id='services'>
        <section className='cards-section'>


          <Carousel responsive={responsive} className='product_container'>
            <div className="Product-Card">
              <img src="images/Commercial-1.png" className="--Talbot-Card_img" alt="..." />
              <div className="card-body paragraph---Txt">
                <h3 className='Area--name'>Commercial</h3>
                <p className='address'>Talbots Commercial specializes in connecting businesses with prime commercial real estate properties, offering tailored solutions that meet the unique requirements of each client. Our expertise and market knowledge ensure seamless transactions and successful outcomes.</p>
              </div>
            </div>
            <div className="Product-Card">
              {/* <img src="images/Commercial-1.png" className="--Talbot-Card_img" alt="..." /> */}
              <video className="--Talbot-Card_img" autoPlay muted loop>
                <source src="images/office_space.mp4" type="video/mp4" />
              </video>
              <div className="card-body paragraph---Txt">
                <h3 className='Area--name'>Office Space</h3>
                <p className='address'>Unlock your business's potential with Talbots Commercial's exceptional office space solutions. From modern high-rise buildings to flexible coworking spaces, we offer a wide range of options that cater to your specific needs, providing a productive and professional environment for your team.</p>
              </div>
            </div>
            <div className="Product-Card">
              <img src="images/Industrial-1.png" className="--Talbot-Card_img" alt="..." />
              <div className="card-body paragraph---Txt">
                <h3 className='Area--name'>Industrial</h3>
                <p className='address'>Talbots Commercial understands the importance of efficient industrial spaces for manufacturing, warehousing, and distribution operations. With our extensive network and industry expertise, we assist clients in finding optimal industrial properties that align with their logistical requirements and growth strategies.</p>
              </div>
            </div>
            <div className="Product-Card">
              <img src="images/property_managment.jpg" className="--Talbot-Card_img" alt="..." />
              <div className="card-body paragraph---Txt">
                <h3 className='Area--name'>Property Management</h3>
                <p className='address'>Entrust the management of your commercial real estate portfolio to Talbots Commercial's experienced property management team. We provide comprehensive services, including tenant relations, lease administration, maintenance, and financial reporting, ensuring that your properties are well-maintained and maximize their value.</p>
              </div>
            </div>
            <div className="Product-Card">
              <img src="images/Retail-1.png" className="--Talbot-Card_img" alt="..." />
              <div className="card-body paragraph---Txt">
                <h3 className='Area--name'>Retail</h3>
                <p className='address'>Talbots Commercial specializes in retail properties that drive business success. Whether you're seeking high-visibility storefronts, shopping centers, or specialized retail spaces, our experts will guide you through the process, helping you secure a location that attracts customers and supports your retail goals.</p>
              </div>
            </div>
          </Carousel>
        </section>
      </div>
    </>
  )
}

export default LatestList
